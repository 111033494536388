.core--table {
    height: 100%;
    display: flex;
    flex-direction: column;

    &.loading {
        pointer-events: none;
    }

    .bids-pagination {
        margin: 20px 20px 0 20px;
    }
}