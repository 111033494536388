// Imported in style.scss, DO NOT REIMPORT
.headline-h-900 {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: var(--N900);
}

.headline-h-800 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: var(--N900);
}

.headline-h-700 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: var(--N900);
}

.headline-h-600 {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: var(--N900);
}

.headline-h-500 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: var(--N900);
}

.headline-h-400 {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: var(--N900);
}

.headline-h-300 {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: var(--N900);
}

.heading-200 {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: var(--N900);
}

.heading-100 {
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    color: var(--N900);
}

.paragraph-500 {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: var(--N800);
}

.paragraph-400 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--N800);
}

.paragraph-300 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--N800);
}

.paragraph-200 {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--N800);
}

.paragraph-100 {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--N800);
}

.text-lg-semibold {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--N800);
}

.text-lg-medium {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--N800);
}

.text-lg-regular {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--N800);
}

.text-md-semibold {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: var(--N800);
}

.text-md-medium {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--N800);
}

.text-md-regular {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--N800);
}

.text-sm-semibold {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: var(--N800);
}

.text-sm-medium {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: var(--N800);
}

.text-sm-regular {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--N800);
}

.text-xs-semibold {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: var(--N800);
}

.text-xs-medium {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: var(--N800);
}

.text-xs-regular {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: var(--N800);
}

.elevation-1 {
    box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.08);
}

.elevation-2 {
    box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.16);
}

.elevation-3 {
    box-shadow: 0px 12px 40px rgba(16, 24, 64, 0.24);
}

.MuiTypography-h6 {
    font-family: "SF Pro Display", "Roboto", "Helvetica", "Arial", sans-serif;
}

.MuiTypography-h5 {
    font-family: "SF Pro Display", "Roboto", "Helvetica", "Arial", sans-serif;
}

.MuiTypography-h4 {
    font-family: "SF Pro Display", "Roboto", "Helvetica", "Arial", sans-serif;
}

.MuiTypography-h3 {
    font-family: "SF Pro Display", "Roboto", "Helvetica", "Arial", sans-serif;
}

.MuiTypography-h2 {
    font-family: "SF Pro Display", "Roboto", "Helvetica", "Arial", sans-serif;
}

.MuiTypography-h1 {
    font-family: "SF Pro Display", "Roboto", "Helvetica", "Arial", sans-serif;
}