.core--time-display{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    gap: 2px;

    .time-frame{
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 28px;
        min-height: 28px;
        border: 1px solid var(--N200);
        border-radius: 4px;
    }

    .time-label{
        color: var(--N800);
        font-weight: 600;
    }

}