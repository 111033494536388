.core--chip-completed {
    height: 16px;

    span {
        font-size: 12px;
        line-height: 12px;
        font-weight: 500;
        color: var(--N800);
        padding-left: 8px;
        padding-right: 8px;
    }
}