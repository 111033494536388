
.core--bid-round-chip.MuiChip-root {
    width: fit-content;
    justify-content: inherit;
    height: 27px;
    background-color: #f1f1f1;

    .MuiChip-label{
        padding: 8px;
        font-size: 14px;
        line-height: 11px;
    }
}

