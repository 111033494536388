.unauthorizedPage {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.unauthorizedContent {
    margin-top: -5%;
    text-align: center;
}