.split-button-container {
    .MuiButton-contained {
        &.split-button-stick-together-label {
            margin: 0;
            width: 160px;
        }

        &.split-button-stick-together-arrow {
            margin: 0;
            width: 50px;
        }
    }
    .split-button-progress {
        padding-left: 5px;
        display: flex;
    }
}

.split-button-popper {
    width: 204px;
    z-index: 3;
}