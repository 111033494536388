.login-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    background-color: #FAFAFA;
    padding-top: 5em;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;

    .login-content {
        box-shadow: none;
        background: var(--N75);
    }

    .login-card-content {
        padding: 0;

        .login-title-text {
            text-align: center;
            margin-bottom: 1rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
        }

        form {
            >div:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        .input-label {
            margin-bottom: 0.5rem;
            margin-top: 5px;
            color: #212529;
        }

        .buttonSubmit {
            color: white;
            background: #3C6A75;
            border-color: #3C6A75;
            margin-top: 18px;
            font-size: 14px;
            font-weight: 500;
            width: 100%;
            border-radius: 4px;
            padding: 8px;

            &:hover {
                background-color: #16272B;
                border-color: #16272B;
            }
        }
    }

    .copyRights {
        margin-top: auto;
        padding-bottom: 2em;
    }

    .spinner-container {
        display: contents;
        position: relative;
        margin-top: 100px;
        margin-left: -125px;
    }
}

@media screen and (max-width: 992px) {
    .sb-login-container {
        background-color: #f2f2f2;
        margin: 0 14px;

        .sb-login-container>div:first-child {
            width: 100%;
            margin-top: 10%;
        }

        .MuiCardContent-root:last-child {
            padding: 24px;
            display: flex;
            justify-content: center;
        }

        .sb-login-container .login-header {
            height: 80px;
            display: flex;
            place-items: center;
            justify-content: center;
        }

        .login-title-text {
            font-weight: 600;
            font-size: 1.1em;
            text-align: center;
            margin-bottom: 2rem;
        }

        .login-card-content {
            display: flex;
            flex-flow: column;
        }
    }
}