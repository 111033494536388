.core--table-dataview-content {
    height: 100%;
    display: flex;
    flex-direction: column;

    .header-checkbox-cell {
        .rs-checkbox {
            margin-top: -8px;
            margin-left: 0px;
        }
    }

    .core--table-dataview-row {
        .name-column {
            display: flex;

            .award-icon {
                margin-right: 5px;
            }
        }

        &.awarded {
            font-weight: bolder;
        }

        &.alert-warning div, .alert-warning span {
            background-color: var(--alert-warning);
            color: var(--alert-warning-text);
            span {
                color: var(--alert-warning-text);
            }
            svg{
                color: var(--alert-warning-icon);
            }
        }

        &.alert-warning:hover div, .alert-warning:hover span {
            background-color: var(--alert-warning-hover);
        }

        &.alert-error div, .alert-error span{
            background-color: var(--alert-error);
            color: var(--alert-error-text);
            span {
                color: var(--alert-error-text);
            }
            svg {
                color: var(--alert-error-icon);
            }
        }

        &.alert-error:hover div, .alert-error:hover span {
            background-color: var(--alert-error-hover);
        }

        &.alert-info div, .alert-info span {
            background-color: var(--alert-info);
            color: var(--alert-info-text);
            span {
                color: var(--alert-info-text);
            }
            svg {
                color: var(--alert-info-icon);
            }
        }

        &.alert-info:hover div, .alert-info:hover span {
            background-color: var(--alert-info-hover);
        }

        &.alert-success div, .alert-success span {
            background-color: var(--alert-success);
            color: var(--alert-success-text);
            span {
                color: var(--alert-success-text);
            }
            svg

            {
                color: var(--alert-success-icon);
            }
        }

        &.alert-success:hover div, .alert-success:hover span {
            background-color: var(--alert-success-hover);
        }
    }
}