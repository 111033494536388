.MuiChip-root.core--status-chip,
.core--status-chip {
    color: var(--B600);
    background-color: var(--B200);
    height: 24px;

    .MuiChip-label {
        padding: 8px;
        font-size: 14px;
        line-height: 11px;
        text-transform: capitalize;
    }

    &.draft {
        color: var(--N800);
        background-color: var(--N400);
    }

    &.approved {
        color: var(--G800);
        background-color: var(--G200);
    }

    &.closed,
    &.refused,
    &.unawarded {
        color: var(--R600);
        background-color: var(--R100);
    }

    &.pending {
        color: var(--Y600);
        background-color: var(--Y100);
    }

    &.completed,
    &.declined,
    &.submitted {
        color: var(--N0);
        background-color: var(--B500);
    }

    &.unsubmitted {
        color: var(--N0);
        background-color: var(--N600);
    }

    &.cancelled {
        color: var(--N0);
        background-color: var(--N900);
    }

    &.canceled {
        color: var(--N0);
        background-color: var(--N900);
    }

    &.shifts {
        color: var(--B600);
    }

    &.active {
        color: var(--G600);
        background-color: var(--G200);
    }


}

@media only screen and (max-width: 900px) {
    .core--status-chip {
        height: 18px;

        .MuiChip-label {
            font-size: 12px;
            line-height: 12px;
        }
    }
}