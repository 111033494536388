.rsuite-v5-date-range-picker-wrapper {
    position: relative;

    .selected-label {
        position: absolute;
        z-index: 10;
        border-radius: 5px;
        margin-left: 10px;
        font-size: 0.82em;
        background: white;
        top: -8px;
        padding: 0 5px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.57);
        transition: all .3s .3s cubic-bezier(.5, 0, .5, 1);
        transition-property: opacity, transform;

        &.hidden {
            opacity: 0;
            transform: scale(0.8);
        }
    }

    .rs-picker-daterange {

        .rs-picker-toggle.rs-picker-toggle-active,
        .rs-picker-toggle.rs-btn:focus,
        .rs-picker-toggle.rs-btn:hover {
            border-color: var(--B400);
            box-shadow: none;
        }

        .rs-picker-toggle.rs-btn-lg,
        .rs-picker-toggle {
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 16px;
            padding-bottom: 17px;

            .rs-picker-toggle-value {
                color: var(--rs-text-primary);
                font-size: 16px;
            }

            .rs-picker-toggle-caret {
                top: 16px;
                left: 8px;
                color: var(--B400);
            }

            .rs-picker-toggle-clean {
                top: 16px;
            }
        }
    }

    .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
    .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
        right: 13px;
    }

    .rs-picker-toggle-textbox {
        padding-left: 29px;
        padding-right: 30px;
        margin-top: -1px;
        display: none;
    }

    .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-textbox {
        font-size: 0.9rem;
        padding-left: 29px;
        margin-top: 0;
    }
}

.rsuite-v5-date-range-picker-wrapper-error {

    .rs-picker-date.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle,
    .rs-picker-daterange.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
        border-color: red;
    }
}

.rsuite-date-range-picker-with-ranges {
    .rs-picker-daterange-panel {
        display: flex;
        flex-direction: row-reverse;

        .rs-picker-toolbar {
            border-top: none;
            margin-top: 0;

            .rs-picker-toolbar-ranges {
                max-height: 238px;
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                button {
                    color: #575757;
                    border-radius: 0;
                    width: 100%;
                    text-align: left;
                    padding: 3px 10px;
                    flex-shrink: 0;

                    &:hover {
                        background-color: #eee;
                        text-decoration: none;
                    }
                }
            }

            .rs-picker-toolbar-right {
                float: none;
                margin-top: 10px;
            }
        }

        .rs-picker-toolbar-option:not(:last-child)::before {
            background-color: transparent;
        }
    }
}

.rs-picker-menu {
    opacity: 1 !important;
    z-index: 1301;
}

.rs-picker-default.rs-picker-disabled .rs-btn {
    background-color: hsl(0, 0%, 95%) !important;

    .rs-picker-toggle-value {
        color: hsl(0, 0%, 60%);
    }
}

.rs-picker-disabled {
    opacity: 1;
}

.rs-picker-toggle-value {
    font-size: 15px;
    line-height: 21px;
}