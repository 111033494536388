.avatar-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 20px;
    border: 1px solid #bdbdbd;
    .label{
        margin-left: 10px;
        margin-right: 5px;
    }
    .number{
        font-weight: bolder;
        margin-right: 10px;
    }
    .avatar{
        margin-right: 0px;
    }
}
