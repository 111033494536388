.core--error-display {
    height: calc(100% - 64px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    >div {
        margin-top: -100px;
    }
}