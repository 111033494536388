@import '~react-redux-spinner/dist/react-redux-spinner.css';
@import url('https://fonts.cdnfonts.com/css/sf-pro-display');
@import 'styles/fonts';
@import 'styles/colors';

// GLOBAL SHARED STYLE
html,
body,
body>div#root {
    height: 100%;
    background-color: var(--N75);
}

html {

    body,
    .MuiInputBase-root,
    .MuiTab-root,
    .MuiButton-contained,
    .MuiMenuItem-root,
    .MuiFormLabel-root,
    .MuiTypography-h6,
    .MuiTypography-h5,
    .MuiTypography-h4,
    .MuiTypography-h3,
    .MuiTypography-h2,
    .MuiTypography-h1,
    .MuiAutocomplete-paper,
    .MuiTypography-body1,
    .MuiTypography-body2,
    .MuiTypography-body3,
    .MuiAlert-root,
    .MuiTableCell-root,
    .MuiInputBase-input,
    .MuiButton-root {
        font-family: "SF Pro Display", "Roboto", "Helvetica", "Arial", sans-serif;
    }
}

.rs-table-cell-header {
    font-weight: bolder
}

#nprogress {

    .bar,
    .spinner {
        z-index: 99999;
    }
}

.rs-table-cell-header-sort-wrapper svg {
    height: 1.3em;
    width: 1.3em;
}

.redux-toastr {
    .rrt-confirm-holder {
        .rrt-confirm {
            max-width: 600px;
            width: auto;
            top: 40%;
            left: 50%;

            .rrt-buttons-holder {
                justify-content: flex-end;
                padding: 20px;
                gap: 15px;

                .rrt-button {
                    flex-grow: unset;
                    display: flex;
                    align-items: center;
                    height: 41px;
                    font-size: 14px;
                    line-height: 41px;
                    font-weight: 500;
                    padding: 0 14px;
                    text-transform: capitalize;
                    border: 1px solid red;
                    border-radius: 4px;

                    &:hover {
                        background-color: #E6EDF5;
                    }

                    &.rrt-ok-btn {
                        color: #FFFFFF;
                        border: 1px solid var(--B400);
                        box-shadow: 0px 4px 8px #0a54a240;
                        background-color: var(--B400);
                        text-transform: capitalize;

                        &:active {
                            color: #FFFFFF;
                            border: 1px solid var(--B400);
                            background-color: var(--B400);
                        }

                        &:hover {
                            background-color: var(--B500);
                        }
                    }

                    &.rrt-cancel-btn {
                        color: var(--B400);
                        border: 1px solid #E6EDF5;
                        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
                        background-color: #FFFFFF;

                        &:active {
                            color: var(--B400);
                            border: 1px solid #B5CBE3;
                            background-color: #EDF2F8;
                        }

                        &:hover {
                            background-color: #E6EDF5;
                        }
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }
        }
    }
}

html {

    .MuiButton-containedPrimary,
    .MuiButton-contained {
        text-transform: capitalize;
    }

    .MuiInput-underline:after {
        border-color: var(--B400);
    }

    .MuiButton-containedPrimary:hover,
    .MuiButton-contained:hover,
    .MuiSwitch-colorPrimary.Mui-checked+.MuiSwitch-track {
        background-color: var(--B500);
    }

    .MuiButton-containedPrimary,
    .MuiButton-contained,
    .MuiButton-contained:hover,
    .MuiButton-containedPrimary:hover,
    .MuiButton-contained.MuiButton-containedSecondary {
        box-shadow: none;
    }

    .MuiButton-contained.MuiButton-containedSecondary:not(.Mui-disabled) {
        background-color: var(--N0);
        border: 1px solid var(--N400);
    }

    .MuiButton-contained.MuiButton-containedSecondary:hover {
        background-color: var(--N50);
    }

    .MuiTab-textColorPrimary.Mui-selected,
    .MuiFormLabel-root.Mui-focused,
    .MuiCheckbox-colorPrimary.Mui-checked,
    .MuiSwitch-colorPrimary.Mui-checked {
        color: var(--B400);
    }

    .MuiCheckbox-colorPrimary.Mui-checked.Mui-disabled,
    .MuiSwitch-colorPrimary.Mui-checked.Mui-disabled {
        color: var(--N400);
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: var(--B400);
    }

    [class^="PrivateTabIndicator-colorPrimary"],
    [class*=" PrivateTabIndicator-colorPrimary"],
    .MuiButton-containedPrimary,
    .MuiButton-contained {
        background-color: var(--B400);
    }

    .MuiAccordionSummary-content {

        .MuiAvatar-root,
        .MuiAvatar-colorDefault {
            background-color: var(--B400);
        }
    }

    .MuiAccordionSummary-root {
        border: 1px solid var(--N50);
        background-color: var(--N50);
    }

    .MuiButtonBase-root.MuiTab-root {
        text-transform: capitalize;
        font-weight: 500;
    }

    .MuiAlert-standardInfo {
        background-color: var(--B100);
    }

    .MuiAlert-standardInfo .MuiAlert-icon {
        color: var(--B400);
    }

    .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:before,
    .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:before {
        border-color: var(--B400);
        background-color: var(--B400);
    }

    .MuiAvatar-root {
        height: 32px;
        width: 32px;
    }

    h6,
    .MuiTypography-h6 {
        color: var(--N900);
        font-size: 16px;
    }

    .rs-table-cell-header {
        .rs-table-cell-content {
            text-transform: uppercase;
            font-weight: 600;
            color: var(--N800);
            font-size: 12px;
        }
    }

    .rs-table-cell-header-sort-wrapper {
        .rs-icon {
            color: var(--B400);
            height: 1em;
            width: 1em;
        }
    }

    .rs-table-cell {
        color: var(--N900);
        font-size: 14px;

        &:not(.rs-table-cell-first) {
            .rs-table-cell-content {
                color: var(--N800);
            }
        }
    }

    .MuiListItem-root.Mui-selected {
        background-color: rgba(0, 0, 0, 0.035);
    }

    .fc {
        .fc-toolbar {
            justify-content: flex-start;
            gap: 20px;

            .fc-button-group {
                gap: 10px;
            }

            .fc-toolbar-title {
                font-size: 16px;
            }

            &.fc-header-toolbar {
                margin-bottom: 10px;
            }
        }

        &.fc-theme-standard {
            .fc-col-header {
                th {
                    padding: 10px 0;
                    vertical-align: middle;
                    text-transform: uppercase;
                    background-color: var(--N75);
                }
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    html {
        .fc {
            .fc-toolbar {
                justify-content: space-between;
                gap: 0;
            }
        }
    }
}