.core--card-period-container {
    width: 100%;
    min-width: 180px;

    .MuiCardContent-root:last-child {
        padding-bottom: 16px;
    }

    .core--period-display,
    .core--date-display {
        span {
            &:first-child {
                display: block;
                color: var(--N900);
                font-weight: 600;
            }
        }
    }
}