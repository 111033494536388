.core--app-bar-content {

    .core--app-bar-left-content {
        width: 100%;
        margin-top: 4px;
        display: flex;
        margin-left: 20px;

        &.pointing {
            cursor: pointer;
        }

        .MuiTypography-h6 {
            font-weight: bold;
        }

        >div {
            display: flex;
            flex-direction: column;
            gap: 2px;

            span:last-child {
                color: var(--N700);
            }
        }

        .core--app-icon-button {
            margin-right: 1rem;
        }
    }

    .core--app-bar-right-content {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        width: 100%;
        margin-right: 20px;

        h6 {
            color: lightgray;
        }

        .user-avatar {
            font-size: 14px;
            line-height: 15px;

            &.MuiAvatar-colorDefault {
                background-color: var(--N200);
                color: var(--N700);
            }
        }

        .hidden {
            display: none;
        }
    }

    .menu-icon-wrapper {
        display: flex;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: var(--N50);
        }

        .menu-icon {
            color: var(--N700);
            cursor: pointer;
        }
    }
}

@media only screen and (max-width: 600px) {
    .core--app-bar-content {
        .core--app-bar-right-content {
            margin-right: 0;
        }
    }
}