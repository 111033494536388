.Loader,
.Loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}

.Loader {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 150;
    font-size: 10px;
    position: absolute;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(6, 78, 135, 0.2);
    border-right: 1.1em solid rgba(6, 78, 135, 0.2);
    border-bottom: 1.1em solid rgba(6, 78, 135, 0.2);
    border-left: 1.1em solid #064e87;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}