.core--transferList {
    margin: auto;
    display: flex;
    min-height: 280px;


    .bidirectional-arrows--button {
        min-width: 36px;
    }

    .bidirectional-arrows--button:not([disabled=""]) {
        background-color: var(--B400);
        color: white;
    }

    @media (min-width: 587px) {
        flex: 1;
        max-width: 1150px;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        >:nth-of-type(2) {
            margin-left: 22px;
            margin-right: 22px;
        }

        .bidirectional-arrows--button-group {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 80px;
        }

        .bidirectional-arrows--button:first-child {
            border-bottom-color: rgba(0, 0, 0, 0.23);
        }
    }

    @media (max-width: 586px) {
        flex-direction: column;
        padding-top: 26px;
        padding-bottom: 26px;
        justify-content: center;
        align-items: flex-start;

        .core--transferList-button {

            .core--bidirectional-arrows {
                justify-content: space-between;
                flex-direction: column;
                min-width: 94px;
                display: flex;
            }

            .bidirectional-arrows--button:first-child {
                border-right-color: rgba(0, 0, 0, 0.23);
            }

        }
    }

    >:nth-of-type(2) {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .core--transferList-control-buttons {
        display: flex;
    }

    .core--transferList-customList {
        width: 280px;
        height: 250px;
        overflow-y: scroll;

        .core--transferList-listItemElement {
            padding: 3;
            font-weight: 600;
        }

        .core--transferList-itemFoundInSearch {
            width: fit-content;
        }

        .core--transferList-itemNotFoundInSearch {
            color: #AAAAAA;
        }
    }

    .core--transferList-paper {
        min-width: 190px;

        .core--transferList-listHeader {
            border-bottom: 1px solid #EEEEEE;
        }

        .core--transferList-headerTitle {
            width: fit-content;
            padding: 10px 15px;
            font-weight: 600;
        }

        .core--transferList-headerActions {
            margin: 4px;
        }

        .core--transferList-headerSearch {
            display: inline-block;
            width: 70%;
            padding-left: 5px;
            vertical-align: middle;
        }

        .core--transferList-checkAll {
            display: inline-block;
            width: 20%;
            vertical-align: middle;
        }


    }

}