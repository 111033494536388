.input-field-container {
    position: relative;

    span[role=alert] {
        position: absolute;
        top: -18px;
        right: 0;
        color: red;
    }
}
