@import '~react-redux-spinner/dist/react-redux-spinner.css';
@import url('https://fonts.cdnfonts.com/css/sf-pro-display');

body {
    margin: 0;
    font-family: "SF Pro Display", "Roboto", "Helvetica", "Arial", sans-serif;
}

// Calendar material-ui override
.fc-header-toolbar {
    padding: 10px 20px 0 20px;
}

.fc {

    a,
    a:focus,
    a:hover {
        color: rgba(0, 0, 0, 0.70);
        text-decoration: none;
    }
}

// TODO: finish implementation in later iterations (hidden for v1),
// Temporary hide the steps component in the manager section - edit bid profile
.manager--bid-edit-steps {
    display: none;
}

// TODO: finish implementation in later iterations (hidden for v1), summary should be present,
// see material guidelines here: https://material.io/archive/guidelines/components/expansion-panels.html#
.accordion-edit--header-summary {
    display: none;
}

.rs-modal-open .rs-modal-wrapper {
    z-index: 10000 !important;

    .rs-modal-xs {
        width: 370px;

        .rs-modal-content {
            padding: 10px;
        }
    }
}

.MuiAutocomplete-popper {
    z-index: 10001;
}

body .MuiTooltip-popper {
    z-index: 9999999;
}

body .rs-picker-daterange-menu {
    z-index: 9999;
}

// react redux toaster
.rrt-text{
    white-space: pre-wrap;
}