.core--controlled-autocomplete {
    &.error {
        label {
            color: red;
        }

        fieldset,
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: red;
        }
    }
}