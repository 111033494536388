.core--action-icon-button {
    .action-icon-button {
        font-size: 16px;
        padding: 17px 10px;
        border: 1px solid hsl(0, 0%, 80%);
        height: 38px;
        width: 40px;
        border-radius: 5px;
        margin: 0 10px 0 0;
    }
}