.core--labeled-control {
    label {
        display: block;
        margin-bottom: 5px;
    }

    &.disabled {
        label {
            opacity: 0.6;
        }
    }
}