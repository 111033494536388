.core--additional-info {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;

    &.visible {
        pointer-events: inherit;
        opacity: 1;
    }

    .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
        color: var(--N700);
    }
}

.core--additional-info--popover.rs-popover {
    max-width: 500px;
    z-index: 1300;
}