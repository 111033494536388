.core--status-chip {

    &.priority-expired {
        color: var(--Y600) !important;
        background-color: var(--Y100) !important;
    }

    &.priority-active {
        color: var(--G600) !important;
        background-color: var(--G200) !important;
    }
}