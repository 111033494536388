.accordion-edit {
    .accordion-edit--header-avatar {
        background-color: #0A54A2;
    }

    .accordion-edit--header-main {
        display: flex;
        align-items: center;
        min-width: 300px;
        margin-right: 10px;

        .MuiAvatar-root {
            margin-right: 25px;
        }
    }

    .accordion-edit--header-summary {
        color: gray;
        opacity: 0.7;
        transition: opacity 0.25s ease-in-out;
    }

    .Mui-expanded {
        .accordion-edit--header-summary {
            opacity: 0;
        }
    }

    .MuiAccordionDetails-root {
        flex-direction: column;
        padding: 0;
    }

    .accordion-edit--details {
        padding: 30px;
    }

    .apply-button {
        svg {
            margin-right: 10px;
        }
    }

    .MuiAccordionSummary-root {
        padding: 0px 30px;
    }

    .MuiAccordionActions-root {
        padding: 8px 30px;
    }
}