.core--obsolete-daterange-picker {
    position: relative;

    .core--placeholder {
        position: absolute;
        top: -8px;
        left: 20px;
        z-index: 1;

        .core--placeholder-label {
            color: #878787;
            background: white;
            padding: 0 4px;
            border-radius: 2px;
            flex: 1;
            font-size: 12px;
        }
    }

    &.disabled {
        .core--placeholder-label {
            color: rgba(0, 0, 0, 0.26);
        }
    }

    &.has-error {
        .picker-button {
            border-color: red;

            &:hover {
                border-color: red;
            }

            .date-picker-icon {
                color: red;
            }
        }
    }

    .picker-button {
        padding: 17px 15px 12px 15px;
        background: var(--N0);
        border: 1px solid rgba(0, 0, 0, 0.23);
        min-width: 300px;

        >.MuiButton-label {
            justify-content: space-between;
            color: var(--rs-text-primary);
        }

        &:hover {
            border-color: var(--B400);
            background: var(--N0);
        }

        .date-picker-icon {
            color: var(--B400);
        }

        .calendar-icon {
            margin-right: 10px;
            height: 20px;
            width: 20px;
            margin-bottom: -2px;
        }

        .chevron-icon {
            margin-left: 10px;
        }

        .disabled {
            .date-picker-icon {
                color: rgba(0, 0, 0, 0.26);
            }
        }
    }
}

.daterangepicker {
    &.show-ranges {
        .ranges {
            overflow-y: auto;
            max-height: 245px;
        }
    }

    td.in-range {
        background-color: var(--B50);
    }

    td.active,
    td.active:hover {
        background-color: var(--B400);
    }

    .ranges li.active {
        background-color: var(--B400);
        color: #fff;
    }

    .btn-primary {
        background-color: var(--B400);
        border-color: var(--B400);
    }
}

.MuiButtonBase-root {
    &.forever-btn{
        color: white;
    }
}
