.core--datetime-range-picker {
    .rs-picker-daterange {

        .rs-picker-toggle.rs-btn-lg,
        .rs-picker-toggle.rs-btn,
        .rs-picker-toggle {
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 16px;
            padding-bottom: 17px;

            .rs-picker-toggle-value {
                color: var(--rs-text-primary);
            }

            .rs-picker-toggle-caret {
                top: 16px;
                left: 8px;
                color: #357ebd;
            }

            .rs-picker-toggle-clean {
                top: 16px;
            }
        }
    }

    .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
    .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
        top: 10px;
        right: 13px;
    }

    .rs-picker-toggle-textbox {
        padding-left: 29px;
        display: none;
    }

    .rs-picker-date.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle,
    .rs-picker-daterange.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
        padding-top: 16px;
        padding-bottom: 17px;

        .rs-picker-toggle-caret {
            top: 16px;
            left: 8px;
        }

        .rs-picker-toggle-clean {
            top: 16px;
        }
    }
}

.core--datetime-range-picker-error {

    .rs-picker-date.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle,
    .rs-picker-daterange.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
        border-color: red;
    }
}

.core--datetime-range-picker-menu {
    .rs-calendar-time-dropdown {
        width: 50%;
        display: block;
        padding: 0;
        right: 0;
    }

    div[index="0"] {
        .rs-calendar-time-dropdown {
            left: 0;
        }

        .rs-calendar-view {
            width: 50%;
            margin-left: 50%;
        }
    }

    .rs-calendar-view {
        width: 50%;
    }

    &.rs-picker-menu .rs-calendar {
        min-width: 492px;
    }

    .rs-calendar-header-time-toolbar {
        pointer-events: none;
    }
}