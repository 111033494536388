.core--bidirectional-arrows {
    .bidirectional-arrows--button-group {
        padding: 5px;
    }

    .bidirectional-arrows--button {
        width: 30px;
        height: 30px;
        min-width: 30px;
        border-radius: 5px;
    }

    .bidirectional-arrows--arrow-icon {
        font-size: 14px;
    }
}