.core--row-filter {
    min-width: 255px;
    width: auto;

    .selected-option-label {
        padding-left: 6px;
    }
}

body {
    .MuiAutocomplete-popper {
        z-index: 10000000;
    }
}