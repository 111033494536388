.core--table-header {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 13px 0;

    .core--table-header-left {
        display: flex;
        gap: 20px;
        width: 80%;
        justify-content: flex-start;
        flex-wrap: wrap;

        .create-button {
            margin-left: 0;
            text-transform: none;

            span:first-child {
                svg {
                    margin-right: 8px;
                }
            }
        }

        .core--search-container {
            margin: 13px 0;
            display: flex;
            justify-content: space-between;

            .search-box {
                margin-right: 30px;
            }

        }

        @media only screen and (max-width: 900px) {
            width: 100%;
            justify-content: space-evenly;

            .core--search-container {
                width: 95%;
            }

            .core--row-filter-box {
                width: 95%;
            }

        }

    }

    .core--table-header-right {
        display: flex;
        width: 20%;
        justify-content: right;
        gap: 20px;
        margin: 13px 0;

        @media only screen and (max-width: 900px) {
            width: 0%;
        }
    }
}