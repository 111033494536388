.core--time-picker {
    width: 300px;

    .time-picker--toggle-wrapper {
        width: 100%;
        height: 35px;
        border: 1px solid var(--N400);
        border-radius: 4px;
        padding: 6px 10px;
        -webkit-transition: border-color .3s ease-in-out;
        transition: border-color .3s ease-in-out;

        .toggle--placeholder {
            color: var(--N600);
        }

        &.disabled {
            opacity: 0.6;
        }
    }

    .time-picker--toggle-wrapper:hover {
        border-color: var(--B400);

        &.disabled {
            border-color: var(--N400);
        }
    }
}

.time-picker--popover {

    .time-picker--popover-content {
        display: flex;

        .popover-content--column {
            width: 75px;

            .column--list-title {
                width: 100%;
                text-align: center;
                padding: 8px 0px;
            }

            hr {
                border-color: var(--N200);
                margin: 0;
            }

            .column--list {
                height: 200px;
                padding: 0 5px;
                margin: 0;
                overflow-y: scroll;
                overflow-x: hidden;
                list-style: none;
                text-align: center;
                -ms-overflow-style: none;
                scrollbar-width: none;

                .list--list-item {
                    height: 30px;
                    margin: 1px 0;
                    border-radius: 4px;
                    cursor: pointer;

                    .list-item {
                        height: 100%;
                    }

                    .text-sm-semibold {
                        vertical-align: sub;
                        color: var(--N800);
                    }

                    &.selected {
                        background-color: var(--B400);

                        .text-sm-semibold {
                            color: var(--N0);
                        }
                    }
                }

                .list--list-item:hover {
                    background-color: var(--B200);
                }
            }

            .column--list::-webkit-scrollbar {
                display: none;
            }
        }

    }

    .time-picker--popover-toolbar {
        display: flex;
        justify-content: flex-end;
        border: 1px solid var(--N200);
        border-radius: 4px;
        height: 56px;
        padding: 12px 16px;

        .toolbar-right {
            .toolbar-right--submit-button {
                padding: 8px 12px;
                background-color: var(--B400);
                -webkit-transition: background-color .3s linear;
                transition: background-color .3s linear;

                .text-md-semibold {
                    color: var(--N0);
                }
            }

            .toolbar-right--submit-button:hover {
                background-color: var(--B500);
            }
        }
    }
}