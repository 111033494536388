.core--labeled-control {
    display: flex;
    align-items: center;

    label {
        margin-right: 10px;
    }

    .MuiTextField-root {
        width: 65px;

        .MuiOutlinedInput-input {
            padding: 12px;
        }
    }

    .core--additional-info {
        height: 24px;
        margin: 0 10px 0 15px;

        .MuiSvgIcon-root {
            width: 24px;
            height: 24px;
        }
    }

    .rs-popover-content {
        font-size: 14px
    }
}