.core--column-filter {
    .rs-dropdown {
        border: 1px solid lightgrey;
        border-radius: 5px;
    }

    .rs-dropdown-toggle {
        font-size: 16px;
        border-radius: 5px;
        background-color: #fff;

        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }

    .rs-dropdown-menu {
        overflow-y: auto;
        overflow-x: hidden;

        width: calc(100% + 147px);
        height: 400px;
        padding-left: 5px;
        padding-bottom: 0px;

        .rs-checkbox-group {
            padding-left: 0px;
        }
    }

    .rs-dropdown-item {
        display: flex;
        align-items: center;
        height: 51px;
        padding: 0px;
        margin-left: -5px;

        .rs-checkbox {
            width: 100%;
            padding-top: 0px;
            padding-bottom: 0px;
        }

        .rs-checkbox-checker {
            display: flex;
            align-items: center;
            padding-top: 0px;
            padding-bottom: 0px;

            .rs-checkbox-wrapper {
                top: 19px;
            }
        }
    }

    .rs-checkbox label {
        display: flex;
        width: 100%;
        height: 51px;
        align-items: center;
    }

}