.SettingTitle-SettingTitleWrapper {
    width: 100%;
}

.SettingTitle-SettingSubtitle {
    font-size: initial;
    color: #bbbaba;
    font-weight: 400;
    margin: 0.5rem 0;
}

.SettingTitle-SettingTitle {
    font-size: 1.7rem;
    margin: 0.5rem 0;
}