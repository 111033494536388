.button-wrapper{

    .button{
        width: 30%;
        border: 1px solid var(--B200);

    }

    .icon{
        font-size: 1.3em;
        color: var(--B400);
        margin-right: 15px;
    }

    .position-left{
        text-align: left;
    }

    .position-center{
        text-align: center;
    }

    .position-right{
        text-align: right;
    }
}