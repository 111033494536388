.core--tabs-styled {
    height: 100%;
    display: flex;
    flex-direction: column;

    >*:first-child {
        >div.MuiPaper-root {
            padding-top: 20px;
            background-color: var(--N200);
        }
    }

    button.MuiTab-root {
        text-transform: none !important;
        background-color: white;
        border: 1px solid lightgray;
        border-bottom: none;
    }

    button.MuiTab-root:first-child {
        border-right: none;
    }

    .MuiTabs-scroller {
        margin-left: 40px;
    }
}