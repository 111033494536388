.SelectionCheckbox{
    &>label{
        margin: 0px;
        &>span{
            padding: 5px;
        }
    }
    ::selection{
        color: black;
        background: none;
    }
}


.SelectionNumber{
    font-size: 16px;
    font-weight: 600;
    line-height: 38px;
    text-align: right;
    padding-right: 7px;
    
    &::selection{
        color: black;
        background: none;
    }
    .NumberCentered{
        position: relative;
        top: 50%;
        transform: translate(0%, -50%);
    }
}