.core--user-profile-menu {
    .user-profile-menu-button {
        width: 32px;
        min-width: 32px;
        height: 32px;
        padding: 0;
        border-radius: 50%;

        .user-avatar {
            margin: 0;
        }
    }
}

.user-profile-menu--content {
    min-width: 240px;

    .user-profile {
        display: flex;
        align-items: center;
        padding: 16px;

        .user-avatar {
            margin: 0 6px 0 0;
            background-color: var(--N200);
        }
    }

    hr {
        margin: 2px 0;
    }
    .user-menu-section {
        .user-menu-subtitle {
            padding: 12px 16px 8px 16px;
            text-transform: uppercase;

            .heading-200 {
                color: var(--N600);
            }
        }
    }

    .user-menu-language-item {
        justify-content: space-between;
        padding: 12px 16px;

        .text-md-medium {
            padding: 4px 0;
        }

        .selected, svg {
            color: var(--B400);
        }
    }
    
    .user-menu-about-item {
        display: flex;
        justify-content: space-between;
        padding: 8px 16px;
        
        .label {
            color: var(--N700);
        }

        .value {
            color: var(--N600);
        }
    }
}