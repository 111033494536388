.core--calendar-date-display-container {
    border: 1px solid var(--N200);
    border-radius: 4px;
    height: 58px;
    width: 56px;
    overflow: hidden;

    .core--calendar-date-display {
        overflow: hidden;
        display: flex;
        flex-direction: column;

        span {
            display: block;
            text-align: center;
            font-size: 12px;
            color: var(--N900);
        }

        span.year {
            color: var(--N800);
            line-height: 20px;
            background-color: var(--N200);
        }

        span.month {
            text-transform: uppercase;
            margin-top: 3px;
            background-color: white;
        }

        span.day {
            font-weight: 600;
            margin-top: -3px;
            background-color: white;
        }
    }
}