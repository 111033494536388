#inactivity-prompt {
    .rrt-confirm {
        top: 15%;
        left: 50%;

        .rrt-buttons-holder {
            justify-content: center;
        }
    }

    .rrt-message {
        font-weight: bolder;
    }

    .inactivity-warning {
        min-width: 220px;
        text-align: center;
    }
}