.core--back-button {
    &.MuiIconButton-root {
        border-radius: 2px;
    }

    svg {
        height: 13px;
        width: 13px;

        path {
            stroke-width: 10px;
            stroke: var(--N900);
        }
    }

}