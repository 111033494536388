.core--sidebar-menu {
    .core--action-icon-button {
        display: flex;
        align-items: center;
        min-height: 40px;
        border-radius: 4px;

        &>button {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 10px;
            width: 100%;
            border: none;

            .MuiIconButton-label label {
                margin-left: 10px;
                cursor: pointer;
            }

            span.MuiIconButton-label {
                color: var(--N700);
                font-weight: 500;
                line-height: 16px;
            }
        }

        &.menu-item.active {
            background-color: var(--B100);
            cursor: default;
            pointer-events: none !important;

            &:hover,
            & *:hover {
                cursor: default;
                pointer-events: none !important;
            }

            &>button {
                span.MuiIconButton-label {
                    color: var(--B500);

                }
            }
        }
    }

    .sidebar-content-section {
        display: flex;
        flex-direction: column;
        padding-top: 24px;
        gap: 10px;
        width: 95%;

    }

    .sidebar-title-section {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 13px;
        border-bottom: 1px solid var(--N200);

        button.action-icon-button {
            display: flex;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }

        .title {
            display: flex;
            flex-direction: column;
            gap: 5px;

            label {
                display: block;

                &:nth-child(1) {
                    color: var(--N700);
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 14px;
                }

                &:nth-child(2) {
                    color: var(--N900);
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 18px;
                }
            }
        }
    }
}

.MuiPaper-root.MuiDrawer-paper {
    &.MuiPaper-elevation16 {
        min-width: 285px;
        background-color: var(--N0);
    }
}