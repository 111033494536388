:root {
    --N900: #001233;
    --N800: #33415C;
    --N700: #696F8C;
    --N600: #8F95B2;
    --N500: #C1C4D6;
    --N400: #D8DAE5;
    --N300: #E6E8F0;
    --N200: #EDEFF5;
    --N100: #D8DAE5;
    --N75: #F9FAFC;
    --N50: #FAFBFF;
    --N0: #FFFFFF;

    --B600: #1F3D99;
    --B500: #2952CC;
    --B400: #3366FF;
    --B300: #9DB5FF;
    --B200: #D6E0FF;
    --B100: #EBF0FF;
    --B50: #F3F6FF;

    --G600: #317159;
    --G500: #429777;
    --G400: #52BD94;
    --G300: #A3E6CD;
    --G200: #DCF2EA;
    --G100: #EEF8F4;
    --G50: #F5FBF8;

    --Y600: #66460D;
    --Y500: #996A13;
    --Y400: #FFB020;
    --Y300: #FFD079;
    --Y200: #FFDFA6;
    --Y100: #FFEFD2;
    --Y50: #FFFAF1;

    --R600: #7D2828;
    --R500: #A73636;
    --R400: #D14343;
    --R300: #EE9191;
    --R200: #F4B6B6;
    --R100: #F9DADA;
    --R50: #FDF4F4;


    --V600: #524988;
    --V500: #6E62B6;
    --V400: #897AE3;
    --V300: #B8AFEE;
    --V200: #D0CAF4;
    --V100: #E7E4F9;
    --V50: #F8F7FD;


    --T600: #0F5156;
    --T500: #10899E;
    --T400: #25CBD6;
    --T300: #7CE0E6;
    --T200: #A8EAEF;
    --T100: #D3F5F7;
    --T50: #F2FCFD;


    --P600: #8E3374;
    --P500: #BE449B;
    --P400: #ED55C2;
    --P300: #F499DA;
    --P200: #F8BBE7;
    --P100: #FBDDF3;
    --P50: #FEF5FB;


    --O600: #85462B;
    --O500: #B25E3A;
    --O400: #DE7548;
    --O300: #EBAC91;
    --O200: #F2C8B6;
    --O100: #F8E3DA;
    --O50: #FDF7F4;

    --alert-warning: rgb(255,244,229);
    --alert-warning-hover: rgb(255,239,215);
    --alert-warning-text: rgb(102, 60, 0);
    --alert-warning-icon: #ed6c02;

    --alert-error: rgb(253,237,237);
    --alert-error-hover: rgb(250,227,227);
    --alert-error-text: rgb(95,33,32);
    --alert-error-icon: #d32f2f;

    --alert-info: rgb(229,246,253);
    --alert-info-hover: rgb(219,240,251);
    --alert-info-text: rgb(1,67,97);
    --alert-info-icon: #0288d1;

    --alert-success: rgb(237,247,237);
    --alert-success-hover: rgb(227,242,227);
    --alert-success-text: rgb(30,70,32);
    --alert-success-icon: #2e7d32;

    --fc-button-bg-color: #FFFFFF;
    --fc-button-text-color: #696F8C;
    --fc-button-border-color: #D8DAE5;
    --fc-neutral-bg-color: #EDEFF5;
}