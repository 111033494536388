.zcp--root {
    position: relative;
    display: inline-flex;
    
    
    .MuiTypography-caption{
        &.circular-progress-with-label--label {
            line-height: normal;
            padding-left: 3px;
            display: flex;
            align-items: center;
        }
    }
}
