.core--empty-table-text {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        font-size: 120%;
        color: gray;
    }
}