.core--error-message {
    display: none;

    &.show {
        display: flex;
    }

    .alert-error {
        background-color: var(--R50);
        border: 1px solid var(--R200);
        border-radius: 6px;
        padding: 0 12px;

        .text-sm-semibold {
            color: var(--N900);
        }
    }
}