.core--problem-warning {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;

    &.visible {
        height: 34px;
        pointer-events: inherit;
        opacity: 1;
    }

    .MuiSvgIcon-root {
        width: 34px;
        height: 34px;
        color: red;
    }
}