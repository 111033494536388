.core--event-chip {
    color: var(--B600);
    background-color: var(--B200);
    height: 24px;
    border-radius: 4px;

    .MuiChip-label {
        padding: 8px;
        font-size: 14px;
        line-height: 11px;
    }

    &.paycode {
        color: var(--G600);
        background-color: var(--G200);
    }

    &.shifts {
        color: var(--B600);
    }
}

@media only screen and (max-width: 900px) {
    .core--event-chip {
        height: 18px;

        .MuiChip-label {
            font-size: 12px;
            line-height: 12px;
        }
    }
}