.circular-progress-with-label--root {
    position: relative;
    display: inline-flex;
}
.circular-progress-with-label--label-container {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}
.MuiTypography-caption{
    &.circular-progress-with-label--label {
        line-height: normal;
    }
}
