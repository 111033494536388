.core--table-content {
    height: 100%;

    .header-checkbox-cell {
        .rs-checkbox {
            margin-top: -8px;
            margin-left: 0px;
        }
    }

    .core--status-name-cell {
        .rs-table-cell-content {
            display: flex;
            padding-top: 0;
            padding-bottom: 0;
            align-items: center;

            .rs-table-cell-expand-wrapper {
                padding-top: 5px;
            }
        }

        .core--status-name-cell-content {
            display: flex;
        }

        .cell-content--children-row-margin {
            margin-left: 25px;
        }

        .cell-content--no-chldren-row-margin {
            margin-left: 24px;
        }
    }

    .core--tooltip-cell {
        display: flex;
        width: 100%;
    }

    .MuiChip-root {
        height: 22px;
        width: fit-content;
        padding-top: 1px;
        margin-left: 10px;
        background-color: white;
        border: 1px solid var(--N300);
    }

    .core--status-column {
        width: 20px;
    }

    .core--status-cell {
        border-radius: 50%;
        height: 10px;
        width: 10px;
        margin: 5px 5px 5px 0;
        padding: 5px;
    }

    .core--table-row {
        cursor: pointer;

        &:hover {
            .core--action-button-container {
                display: flex;
                animation: growing 200ms linear;
                justify-content: flex-end;
                gap: 5px;
            }
        }
    }

    .core--action-button-container {
        margin-top: -8px;
        display: none;
    }

    .core--status-name-cell {
        .award-icon {
            margin-right: 5px;
        }
    }

    .MuiButton-root {
        &.core--tablerow-action-button {
            color: var(--N700);
            text-transform: initial;

            >span>span {
                margin-left: 5px;
            }

            &.disabled {
                opacity: 0.5;

            }
        }
    }

    .header-cell-container {
        .rs-table-cell-content {
            display: flex;
    
            .rs-table-cell-header-sort-wrapper {
                flex-basis: 100%;
            }
        }
    }
}

.core--status-tooltip {
    text-transform: capitalize;
}