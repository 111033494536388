.core--table-dataview-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    margin: 13px 0;

    .core--table-dataview-header-left {
        display: flex;
        gap: 20px;

        .core--search-container {
            &.hide-search {
                opacity: 0;
                pointer-events: none;
            }
        }

        .core--row-filter {
            &.hide {
                display: none;
            }
        }
    }

    .core--table-dataview-header-right {
        display: flex;
        align-items: center;
    }
}