.MuiTooltip-popper{
    &.ShiftTooltip{
        .MuiTooltip-tooltip{
            box-Shadow: 0px;
            padding: 0px;
            color: black;
            background-color: #FFFFFF;
            border: 1px solid #CCCCCC;
        }
    }
}

.ShiftTooltipContent{
    padding: 5px;
}

.ShiftToolTipShiftSection{
    border-bottom: 1px solid #DDDDDD;
    padding: 5px 10px;

    .ShiftTooltipShiftBlueBox{
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        padding: 5px 10px;
        color: #0B66BE;
        background-color: #D4E0EC;
    }
    
    .ShiftTooltipShiftDate{
        padding: 7px 0px;
        font-size: 13px;
    }
    
    .ShiftTooltipShiftTime{
        font-size: 20px;
        padding: 7px 0px;
    }
}


.ShiftToolTipSegmentsSection{
    padding: 5px 10px;

    .ShiftTooltipSegmentDate{
        padding: 7px 0px;
        font-size: 13px;
        font-weight: bolder;

        .ShiftTooltipSegmentIndex{
            margin-right: 10px;
        }

        & svg {
            width: 20px;
            height: 15px;
        }
    }

    .ShiftTooltipSegmentLocation{
        font-size: 11px;
    }
    .ShiftTooltipSegmentScProfiles-container{
        font-size: 11px;
        padding-top: 5px;
    }
}