.wslt_paper {
    display: block;
    overflow: hidden;
    width: 100%;
    border: 1px solid #DFDFDF;

    .rs-table-cell-first .rs-table-cell-content {
        padding: 0;
    }
}

.wslt_weekSelectionBar {
    border-bottom: 2px solid #EEE;
}

.wslt_table {
    max-width: 100%;
    overflow: auto;
}

.rs-table-row {
    &.wslt_row {
        border-bottom: 1px solid #DDD;
    }
}

.rs-table-cell-header {
    &.wslt_lightgrey {
        [role=columnheader] {
            background-color: rgb(183, 183, 183);
        }
    }
}

.rs-table-cell {
    &.wslt_lightgrey {
        background-color: rgb(183, 183, 183);
        border-left: 1px solid rgb(183, 183, 183);
    }
}

.wslt_shiftElement {
    display: flex;
    width: 98%;
    padding: 2px 4px;
    margin-top: 3px;

    font-size: 10px;
    white-space: nowrap;
    text-align: left;
    overflow: hidden;

    border: 1px solid #DDD;
    border-radius: 3px;
    border-left: 3px solid #0b0ace;
}

.wslt_tableCells {
    border-left: 1px dashed #F3F3F3;

    &.wslt_text_cell {
        padding: 0;

        .rs-table-cell-content {
            font-size: 13px;
            padding: 0 5px;
            overflow: hidden;
            white-space: normal;
        }
    }

    .rs-table-cell-content {
        display: flex;
        flex-direction: column;
        padding: 7px 10px 7px 0px;
    }

    .wslt_rowName_cell_content {
        display: flex;
        align-items: center;

        .wslt_rowName_text {
            white-space: nowrap;
            overflow: hidden;
        }

        .MuiAvatar-root {
            &.group-quantity-avatar {
                width: 25px;
                height: 25px;
                background-color: #E7E7E7;
                color: #3A3A3A;
                font-size: 14px;
                font-weight: 600;
                margin-left: 5px;
            }
        }
    }

}

.wslt_tableCellsHeader {
    padding: 3px 6px;
    font-weight: 400;
    width: 11.111%;
}

.wslt_alignCenter {
    text-align: center;
}

.wslt_flexRight {
    display: flex;
    flex-direction: row-reverse;
}

.wslt_orgJob_text {
    white-space: nowrap;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    width: inherit;
}

.wslt-cell-text-content {
    text-align: left;
    direction: rtl;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.wslt_transfer_icon {
    font-size: 13px !important;
    margin-left: auto;
    margin-right: 8px;
}