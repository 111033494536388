.core--summary-section-container {
    width: 100%;

    .header-container {
        display: flex;
        align-items: center;
        padding: 20px 30px;
    }

    .header-container-left {
        display: flex;
        align-items: center;
        flex: 10;

        .MuiAvatar-root {
            margin-right: 25px;
        }
    }

    .header-container-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 2;

        .edit-icon {
            padding: 8px;
            margin-left: 5px;

            svg {
                width: 20px;
                height: 20px;
            }

            .info-icon {
                color: var(--N700);
            }
        }

        .check-icon {
            margin-left: 8px;
            font-size: 20px;
        }
    }

    .summary-content-container {
        padding: 16px 30px;
        padding-top: 0;
    }
}