.core--accordion-setup {

    .MuiAccordionSummary-content.Mui-expanded {
        margin: 12px 0;
    }

    .accordion-setup--header-main {
        display: flex;
        align-items: center;
        flex-basis: 20%;
        min-width: 200px;
        margin-right: 10px;
    }

    .accordion-setup--header-summary {
        color: gray;
        opacity: 0.7;
        transition: opacity 0.25s ease-in-out;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .Mui-expanded {
        .accordion-setup--header-summary {
            opacity: 0;
        }
    }
}