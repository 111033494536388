.container {
    padding: 30px;

    .action-button {
        color: "#767676";
        padding: "0px";
    }
    
    .content-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: darkgray;

        span {
            text-transform: uppercase;
            font-size: large;
        }
    }
}

