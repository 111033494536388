.fixed-top-position-popover {
    top: 140px !important;
}

.core--select-multi-status {
    margin-top: 11px;
    margin-left: 6px;
    padding-right: 26px;

    .complex-dropdown {
        margin: 12px 0;
        background-color: white;

        .MuiSelect-select {
            min-height: 32px;

            &:focus {
                background-color: white;
            }
        }

        .MuiOutlinedInput-input {
            padding: 5px 23px 4px 10px;
        }

        .MuiChip-root .MuiChip-avatar {
            height: 12px;
            width: 12px;
            border: 1px solid white;
        }

        .core--chip-container {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            background-color: white;
        }

        legend {
            max-width: 0.01px;
        }

        .MuiChip-root {
            border-radius: 5px;
            background-color: rgba($color: #000000, $alpha: 0.05)
        }
    }

    &>.MuiFormControl-root {

        &>.MuiFormLabel-root {
            background-color: #fff;
            margin-top: -6px;
        }

        &>.MuiFormLabel-root.MuiInputLabel-shrink {
            margin-top: 0;
        }

        &>.MuiInputBase-root {
            width: calc(100% + 26px);

            &>.MuiSelect-root.MuiSelect-select {
                background-color: #fff;
                padding: 11px;
            }
        }

    }

}

.core--status-menu-item-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .MuiAvatar-root {
        height: 12px;
        width: 12px;
        border: 1px solid white;
    }
}